export default {
  data() {
    return {
      msg: {
        userId: '',
        comId: '',
      },
    }
  },
  created() {
    const { time, comId, userId } = this.$route.query
    this.msg.userId = userId
    this.msg.comId = comId
    if (time && new Date().getTime() - time > 72 * 60 * 60 * 1000) {
      this.$router.push('/linkInvalid')
    }
  },
}
