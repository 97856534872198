import { get, post, getStatus } from './axios'

export default {
  // 申请成为好友或企业成员
  applyFriend(params) {
    return post('/user/addressBook/applyFriend', params)
  },
  // 获取邀请好友信息
  getRequestFriendInfo(params) {
    return get('/user/passport/getRequestFriendInfo', params)
  },

  // 同意加入企业
  updateResponseNew(params) {
    return post('/user/addressBook/updateResponseNew', params)
  },

  // 获取我的基本信息
  getMyInfo(params) {
    return get('/user/my/getMyInfo', params)
  },
}
