<template>
  <div class="inviteExternalContact">
    <background />
    <img
      v-if="inviteMember.userHeadImg"
      :src="getImgUrl(inviteMember.userHeadImg)"
      alt
      class="headerImg"
    />
    <img v-else src="@/assets/imgs/person.svg" alt class="headerImg" />
    <div class="title">{{ inviteMember.name }}邀请你加入</div>
    <div class="des">{{ inviteMember.comName }}</div>
    <div class="btn" @click="goUrl('/login?redirect=/inviteMemberHasLogin')">
      没有账号，现在注册
    </div>
    <div
      class="goLogin"
      @click="goUrl('/login?redirect=/inviteMemberHasLogin')"
    >
      已有账号，现在登录
    </div>
  </div>
</template>
<script>
  import Api from '@/api/address'
  import mixin from './mixin'

  export default {
    mixins: [mixin],
    data() {
      return {
        inviteMember: {},
        isAdmin: undefined,
      }
    },
    created() {
      const { userId, comId, isAdmin } = this.$route.query
      this.isAdmin = isAdmin
      if (window.sessionStorage.getItem('inviteMember') && !userId) {
        this.inviteMember = this.$jsonPase(
          window.sessionStorage.getItem('inviteMember')
        )
        return
      }
      this.getRequestFriendInfo({
        userId,
        comId,
      })
    },
    methods: {
      getRequestFriendInfo(params) {
        Api.getRequestFriendInfo(params).then(res => {
          this.inviteMember = res
          window.sessionStorage.setItem(
            'inviteMember',
            JSON.stringify({
              ...this.msg,
              ...res,
              isAdmin: this.isAdmin,
            })
          )
        })
      },
      goUrl(url) {
        this.$router.push(url)
      },
    },
  }
</script>
<style lang="less" scoped>
  .inviteExternalContact {
    padding-top: 30px;
    .headerImg {
      display: flex;
      margin: 0 auto;
      margin-bottom: 30px;
      width: 70px;
      height: 70px;
      border: 2px solid rgba(255, 255, 255, 1);
    }
    .title {
      height: 30px;
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 1);
      line-height: 30px;
      text-align: center;
      margin-bottom: 10px;
    }
    .des {
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 1);
      line-height: 22px;
      text-align: center;
      margin-bottom: 37px;
    }
    .btn {
      width: 280px;
      height: 44px;
      background: rgba(22, 118, 255, 1);
      border-radius: 4px;
      font-size: 15px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 44px;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 16px;
    }
    .goLogin {
      height: 21px;
      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(22, 118, 255, 1);
      line-height: 21px;
      text-align: center;
    }
  }
</style>
